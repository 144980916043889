import React, { useState, useRef } from 'react'
import TimePicker from 'rc-time-picker'
import { Clock } from 'akar-icons'
import axios from 'axios'
import moment from 'moment';

import SectionHead from '../../../ui-elements/common/SectionHead'
import UiHeading from '../../../ui-elements/text/UiHeading'
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular'
import FormControl from '../../../ui-elements/forms/FormControl'
import UiCaptionMedium from '../../../ui-elements/text/UiCaptionMedium'
import CustomTooltip from '../../../ui-elements/customization-element/CustomTooltip'
import Divider from '../../../ui-elements/divider/Divider'
import UiButton from '../../../ui-elements/buttons/UiButton'
import UiBaseCheckbox from '../../../ui-elements/forms/UiBaseCheckbox'
import useAuth from "../../../hooks/useAuth";
import { Notify } from '../../../ui-elements/notify/notify';
import Preloader from '../../../preloader/Preloader'

import NumberInput from './NumberInput'

import styles from './DeliverySettings.module.scss'
import './Timepicker.scss'

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const Optimisation = () => {
  const { shopData, setShopData } = useAuth();
  const { deliverySettings = {} } = shopData;
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const initial = useRef({})
  const [settings, setSettings] = useState(() => {
    const obj = {
      sendingLimitsSend: deliverySettings.sendingLimitsSend ?? '',
      sendingLimitsMins: deliverySettings.sendingLimitsMins ?? '',
      skipRecentContactsFor: deliverySettings.skipRecentContactsFor ?? '',
      sendingScheduleFrom: deliverySettings.sendingScheduleFrom ? moment(deliverySettings.sendingScheduleFrom).utcOffset(0) : '',
      sendingScheduleTo: deliverySettings.sendingScheduleTo ? moment(deliverySettings.sendingScheduleTo).utcOffset(0) : '',
      sendOnDays: deliverySettings.sendOnDays ?? []
    }
    initial.current = obj
    return obj
    
  })

  const minuteStep = 5

  const handleChange = (value, key) => {
    setChanged(true)
    setSettings((prev) => ({...prev, [key]: value}))
  }
  
  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    axios.put('/shop', {
      deliverySettings: settings
    }, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(() => {
      setShopData((prev) => {
        const updatedSettings = {
          sendingLimitsSend,
          sendingLimitsMins,
          skipRecentContactsFor,
          sendingScheduleFrom: settings.sendingScheduleFrom.utc(0).format(),
          sendingScheduleTo: settings.sendingScheduleTo.utc(0).format(),
          sendOnDays
        }
        prev.deliverySettings = updatedSettings
        return prev
      })
      setChanged(false);
      initial.current = settings
      
      Notify.success({
        title: 'Changes saved successfully',
        message: 'Delivery settings have been updated.',
      });
    }).catch(err => {
      Notify.error({
        title: 'Something went wrong :(. Try later',
      });
      console.error(err);
    }).finally(() => {
      setLoading(false)
    });
  }
  
  const resetChanges = e => {
    e.preventDefault();
    setSettings(initial.current)
    setChanged(false)
  }

  const {
    sendingLimitsSend, 
    sendingLimitsMins, 
    skipRecentContactsFor, 
    sendingScheduleFrom,
    sendingScheduleTo,
    sendOnDays
  } = settings

	return (
		<div className='an-layout'>
			<SectionHead
				title={
					<>
						<UiHeading
              text='Delivery settings'
							desktop='email-templates-subtitle-2-bold'
						/>
						<UiCaptionRegular
              text='Fine-tune email campaigns for optimal delivery. Set volume limits and refine delivery times. This settings only affects campaigns and not transactional emails.'
							margin='4'
							className='color-secondary-colour-secondary-7'
						/>
					</>
				}
			/>
      <form className='card card_24 card_shadow card_overflow_visible' onSubmit={handleSubmit}>
				<div className='an-layout'>
					<div className='an-row'>
						<div className='an-col an-col_50'>
							<div className={styles['title']}>
                <UiCaptionMedium text='Sending limits' />
								<CustomTooltip
                  tooltip={{
                    description: 'Set email sending limits to prevent spam, protect reputation, and ensure fair use. Exceeding limits may delay or suspend emails.'
                  }}
                />
							</div>
              <div className={styles['limits']}>
                Send
                <FormControl
                  type='number'
                  id='emails_count'
                  name='emailsCount'
                  placeholder="100"
                  value={sendingLimitsSend}
                  min={0}
                  onPaste={(e) => {
                    const clipboardData = e.clipboardData || window.clipboardData;
                    const pastedData = parseFloat(clipboardData.getData('text'));
                    if (pastedData < 0) {
                        e.preventDefault();
                    }
                  }}
                  onChange={e => handleChange(e.target.value, 'sendingLimitsSend')}
                  onKeyDown={(e) => {
                    if (e.code === 'Minus') {
                      e.preventDefault();
                    }
                  }}
                />
                emails within 
                <FormControl
                  type='number'
                  classNameDiv={styles['mins']}
                  id='mins'
                  name='mins'
                  placeholder="2"
                  value={sendingLimitsMins}
                  min={0}
                  onPaste={(e) => {
                    const clipboardData = e.clipboardData || window.clipboardData;
                    const pastedData = parseFloat(clipboardData.getData('text'));
                    if (pastedData < 0) {
                        e.preventDefault();
                    }
                  }}
                  onChange={e => handleChange(e.target.value, 'sendingLimitsMins')}
                  onKeyDown={(e) => {
                    if (e.code === 'Minus') {
                      e.preventDefault();
                    }
                  }}
                />
                mins
              </div>
						</div>
						<div className='an-col an-col_50'>
							<div className={styles['title']}>
                <UiCaptionMedium text='Skip recent contacts for' />
								<CustomTooltip
                  tooltip={{
                    description: 'This setting delays sending to contacts you\'ve recently emailed, reducing inbox clutter.'
                  }}
                />
							</div>
              <div className={styles['wrapper_input']}>
                <NumberInput
                  name='skipRecentContactsFor'
                  onKeyDown={(e) => {
                    if (e.code === 'Minus') {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    const clipboardData = e.clipboardData || window.clipboardData;
                    const pastedData = parseFloat(clipboardData.getData('text'));
                    if (pastedData < 0) {
                        e.preventDefault();
                    }
                  }}
                  value={skipRecentContactsFor}
                  min={0}
                  handleChange={value => handleChange(value, 'skipRecentContactsFor')}
                />
                <span>hours</span>
              </div>
						</div>
					</div>
          <div className='an-row'>
            <div className='an-col an-col_50'>
              <div className={styles['title']}>
                <UiCaptionMedium text='Sending schedule' />
                <CustomTooltip
                  tooltip={{
                    description: 'Set the daily time window for sending emails, choosing start and end times to optimise delivery.'
                  }}
                />
              </div>
              <div className={styles['limits']}>
                From
                <div className={'timepicker'}>
                  <Clock strokeWidth={2} size={24} className={'time-icon'} />
                  <TimePicker
                    placeholder='08:00'
                    minuteStep={minuteStep}
                    showSecond={false}
                    allowEmpty={false}
                    inputReadOnly
                    value={sendingScheduleFrom ? sendingScheduleFrom : null}
                    disabledMinutes={() => {
                      if (sendingScheduleFrom && sendingScheduleTo) {
                        const minHour = sendingScheduleFrom.hour()
                        const maxHour = sendingScheduleTo.hour()
                        if (minHour === maxHour) {
                          const maxMinute = sendingScheduleTo.minute();
                          const disabledMinutes = []
  
                          for (let i = 60; i > maxMinute; i -= minuteStep) {
                            disabledMinutes.push(i);
                          }
                          return disabledMinutes
                        }
                      }
                      return []
                    }}
                    disabledHours={() => {
                      if (sendingScheduleTo) {
                        const maxMinutes = sendingScheduleTo.minute()
                        let maxHour = sendingScheduleTo.hour()
                        maxHour = maxMinutes > minuteStep ? maxHour + 1 : maxHour
                        const arrayLength = 24 - maxHour
                        
                        return Array(arrayLength).fill().map((_, i) => i + maxHour)
                      }
                    }}
                    onChange={(e) => handleChange(e, 'sendingScheduleFrom')}
                  />
                </div> 
                to
                <div className='timepicker'>
                  <Clock strokeWidth={2} size={24} className={'time-icon'} />
                  <TimePicker
                    placeholder='16:00'
                    minuteStep={minuteStep}
                    showSecond={false}
                    allowEmpty={false}
                    inputReadOnly
                    value={sendingScheduleTo}
                    disabledMinutes={() => {
                      if (sendingScheduleFrom && sendingScheduleTo) {
                        const minHour = sendingScheduleFrom.hour()
                        const maxHour = sendingScheduleTo.hour()
                        if (minHour === maxHour) {
                          const minMinute = sendingScheduleFrom.minute();
                          const disabledMinutes = []

                          for (let i = 0; i < minMinute; i += minuteStep) {
                            disabledMinutes.push(i);
                          }
                          return disabledMinutes
                        }
                      }
                      return []
                    }}
                    disabledHours={() => {
                      if (sendingScheduleFrom) {
                        const minMinute = sendingScheduleFrom.minute()
                        let minHour = sendingScheduleFrom.hour()
                        minHour = minMinute >= 60 - minuteStep ? minHour + 1 : minHour
  
                        return Array(minHour).fill().map((_, i) => minHour - i - 1)
                      }
                    }}
                    onChange={e => handleChange(e, 'sendingScheduleTo')}
                  />
                </div>
              </div>
            </div>
            <div className='an-col an-col_50'>
              <div className={styles['title']}>
                <UiCaptionMedium text='Send on days' />
                <CustomTooltip
                  tooltip={{
                    description: 'Select specific days for sending emails to ensure your messages are delivered on the best days for your audience.'
                  }}
                />
              </div>
              <div className={styles['days']}>
                {days.map((day) => 
                  <UiBaseCheckbox
                    labelSpacing={8}
                    name='days[]'
                    label={day}
                    id={day}
                    key={day}
                    value={day}
                    checked={sendOnDays.includes(day)}
                    onChange={e => {
                      let days = e.target.checked ? [...sendOnDays, day] : sendOnDays.filter(d => d !== day)
                      handleChange(days, 'sendOnDays')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
					<Divider />
					<div className='flex-right gap-8 mt-4'>
						<UiButton
							type='button'
							text='Cancel'
							size='medium'
							design='secondary'
              onClick={resetChanges}
              disabled={!changed}
						/>
						<UiButton
							text='Save changes'
							size='medium'
              disabled={!changed}
						/>
					</div>
				</div>
        {loading && (
          <Preloader
            absolute={true}
            overflow={true}
          />
        )}
			</form>
		</div>
	)
}

export default Optimisation