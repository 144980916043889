import { Routes, Route, Outlet } from "react-router-dom";
import WithAuthorization from "./WithAuthorization";
import WithUnAuthorization from "./WithUnAuthorization";
import Login from "../sign-up/Login";
import DashboardPage from "../pages/dashboard/Dashboard";
import DashboardLayout from "../dashboard/Layout";
import SignUp from "../sign-up/SignUp";
import SelectYourPlan from "../sign-up/SelectYourPlan";
import ThankYou from "../sign-up/ThankYou";
import ViewEmail from "../view-email/ViewEmail";
import ForgotPassword from "../sign-up/ForgotPassword";
import ResetPassword from "../sign-up/ResetPassword";
import Onboarding from "../onboarding/Onboarding";
import CampaignsPage from "../pages/campaigns/CampaignsPage";
import LogOut from "../sign-up/LogOut";
import StoreSettingsPage from "../pages/store-settings/StoreSettingsPage";
import DomainAccountsPage from "../pages/store-settings/DomainAccounts";
import DomainAccountsAddPage from "../pages/store-settings/DomainAccountsAdd";
import CreateWidget from "../widgets/CreateWidget";
import EditWidget from "../widgets/EditWidget";
import CampaignPage from "../pages/campaign/CampaignPage";
import OverviewPage from "../pages/campaign/OverviewPage";
import UserActivityPage from "../pages/campaign/UserActivityPage";
import Participants from "../pages/campaign/Participants";
import SingleCampaignPage from "../pages/campaign/SingleCampaignPage";
import SubscribersPage from "../pages/campaign/SubscribersPage";
import WidgetPage from "../pages/widget/WidgetPage";
import WidgetOverviewPage from "../pages/widget/OverviewPage";
import WidgetArchivePage from "../pages/widget/ArchivePage";
import ProfilePage from "../pages/profile/ProfilePage";
import UserDetailsPage from "../pages/profile/DetailsPage";
import TwoFactorAuthenticationPage from "../pages/profile/TwoFactorPage";
import PaymentInfoPage from "../pages/billing/PaymentInfoPage";
import PackagesPage from "../pages/billing/PackagesPage";
import InvoicesPage from "../pages/billing/InvoicesPage";
import BillingPage from "../pages/billing/BillingPage";
import DomainAccountsDomainPage from "../pages/store-settings/DomainAccountsDomain";
import DomainAccountsEmailVerifyPage from "../pages/store-settings/DomainAccountsEmailVerify";
import DomainAccountsEmailAddPage from "../pages/store-settings/DomainAccountsEmailAdd";
import DomainAccountsEmailEditPage from "../pages/store-settings/DomainAccountsEmailEdit";
import StoreInformationPage from "../pages/store-settings/StoreInformationPage";
import StoreUsersPage from "../pages/store-settings/StoreUsersPage";
import DeliverySettings from '../pages/store-settings/DeliverySettings/DeliverySettings';
import BrandAssetsPage from "../pages/brannd-assets/BrandAssetsPage";
import BrandInformationPage from "../pages/brannd-assets/BrandInformationPage";
import BrandIdentifyPage from "../pages/brannd-assets/BrandIdentifyPage";
import BrandLayoutsPage from "../pages/brannd-assets/BrandLayoutsPage";
import EmailHeaderCustomize from "../pages/brannd-assets/EmailHeaderCustomize";
import WithShop from "./WithShop";
import EmailFooterCustomize from "../pages/brannd-assets/EmailFooterCustomize";
import Private from "./Private";
import AccessDeniedPage from "../pages/access-denied/AccessDeniedPage";
import CampaignGeneratePage from "../pages/campaign/GeneratePage";
import CustomersPage from "../pages/crm/CustomersPage";
import SurveysPage from "../pages/campaign/SurveysPage";
import ToolsPage from '../pages/tools/ToolsPage';
import ActivityPage from '../pages/tools/ActivityPage';
import ConsentManagerPage from "../pages/tools/ConsentManagerPage";
import ToolsOverviewPage from '../pages/tools/OverviewPage';
import CustomerFeedbackPage from '../pages/campaign/CustomerFeedbackPage';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Feedback from "../feedback/Feedback";
import CreateNewInstance from "../pages/campaign/CreateNewInstance";
import UploadSubscribersPage from "../pages/private/UploadSubscribersPage";

const AppRoutes = () => {
  const location = useLocation();
  const [fullHeight, setFullHeight] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
  
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<WithUnAuthorization />}>
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/select-your-plan" element={<SelectYourPlan />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>

      <Route path="/view-email/:hash" element={<ViewEmail />} />
      <Route path="/logout" element={<LogOut />} />
      <Route path="/access-denied" element={<AccessDeniedPage />} />

      <Route
        element={
          <WithAuthorization>
            <Feedback />
            <Outlet />
          </WithAuthorization>
        }
      >
        <Route path="/onboarding" element={<Onboarding />} />

        <Route
          element={
            <WithShop>
              <Outlet />
            </WithShop>
          }
        >
          <Route path="/widgets/new" element={<CreateWidget />} />
          <Route path="/widgets/:id" element={<EditWidget />} />
          <Route
            path="/campaigns/:campaignSlug/generate"
            element={<CampaignGeneratePage />}
          />
          <Route
            path="/campaigns/:campaignSlug/:toolSlug/generate"
            element={<CampaignGeneratePage />}
          />
          <Route
            path="/campaigns/:campaignSlug/:campaignId/edit"
            element={<CampaignGeneratePage edit />}
          />
          <Route
            element={
              <Private allowedRoles={[`superadmin`, "admin"]}>
                <Outlet />
              </Private>
            }
          >
            <Route
              path="/store-settings/domain-accounts/add"
              element={<DomainAccountsAddPage />}
            />
            <Route
              path="/store-settings/domain-accounts/:id"
              element={<DomainAccountsDomainPage />}
            />
            <Route
              path="/store-settings/domain-accounts/email/add"
              element={<DomainAccountsEmailAddPage />}
            />
            <Route
              path="/store-settings/domain-accounts/email/:id/verify"
              element={<DomainAccountsEmailVerifyPage />}
            />
            <Route
              path="/store-settings/domain-accounts/email/:id"
              element={<DomainAccountsEmailEditPage />}
            />
          </Route>
          <Route
            path="/brand-assets/email-header"
            element={<EmailHeaderCustomize />}
          />
          <Route
            path="/brand-assets/email-footer"
            element={<EmailFooterCustomize />}
          />
        </Route>

        <Route element={<DashboardLayout />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/campaigns" element={<CampaignsPage />} />

          <Route path="/campaigns/:campaignSlug" element={<CampaignPage fullHeight={fullHeight} />}>
            <Route path="" element={<OverviewPage setFullHeight={setFullHeight} />} />
            <Route path="user-activities" element={<UserActivityPage />} />
            <Route path="participants" element={<Participants />} />
            <Route path="surveys" element={<SurveysPage />} />
            <Route path="subscribers" element={<SubscribersPage />} />
            <Route path=":campaignId" element={<SingleCampaignPage />} />
            <Route path="create" element={<CreateNewInstance />} />
            <Route path="customer-feedback" element={<CustomerFeedbackPage />} />
          </Route>

          <Route path="/widgets" element={<WidgetPage />}>
            <Route path="" element={<WidgetOverviewPage />} />
            <Route path="archive" element={<WidgetArchivePage />} />
          </Route>
          
          <Route path="/tools" element={<div>tools</div>} />
          <Route path="/tools/:toolSlug" element={<ToolsPage />}>
            <Route path="" element={<ToolsOverviewPage />} />
            <Route path="list-reputation" element={<ActivityPage />} />
            <Route path="consent-manager" element={<ConsentManagerPage />} />
          </Route>

          <Route path="/profile" element={<ProfilePage />}>
            <Route path="" element={<UserDetailsPage />} />
            <Route
              path="two-factor-authentication"
              element={<TwoFactorAuthenticationPage />}
            />
          </Route>

          <Route
            element={
              <Private allowedRoles={[`superadmin`]}>
                <Outlet />
              </Private>
            }
          >
            <Route path="/billing" element={<BillingPage />}>
              <Route path="" element={<PaymentInfoPage />} />
              <Route path="packages" element={<PackagesPage />} />
              <Route path="invoices" element={<InvoicesPage />} />
            </Route>
          </Route>

          <Route
            element={
              <Private allowedRoles={[`superadmin`, `admin`]}>
                <Outlet />
              </Private>
            }
          >
            <Route path="/store-settings" element={<StoreSettingsPage />}>
              <Route path="" element={<StoreInformationPage />} />
              <Route path="domain-accounts" element={<DomainAccountsPage />} />
              <Route path="delivery-settings" element={<DeliverySettings />} />
              <Route path="users" element={<StoreUsersPage />} />
            </Route>
          </Route>

          <Route path="/brand-assets" element={<BrandAssetsPage />}>
            <Route path="" element={<BrandInformationPage />} />
            <Route path="brand-identity" element={<BrandIdentifyPage />} />
            <Route path="common-layouts" element={<BrandLayoutsPage />} />
          </Route>

          <Route path="/crm/customers" element={<CustomersPage />} />
          <Route path="/private/subscribers/upload" element={<UploadSubscribersPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;