import { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import MainContent from "../../dashboard/MainContent";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import UiButton from "../../ui-elements/buttons/UiButton";
import Preloader from "../../preloader/Preloader";
import Error from "../../ui-elements/error/Error";

const UploadSubscribersPage = () => {
  const [disabled, setDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [error, setError] = useState(null);

  const getFormData = file => {
    const formData = new FormData();

    formData.append('file', file);

    return formData;
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        setError(fileRejections[0].errors[0].message);

        return;
      }

      if (acceptedFiles.length) {
        setIsUploading(true);

        const formData = getFormData(acceptedFiles[0]);

        axios
          .post('subscribers/upload', formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem('token')}`,
            }
          })
          .then(response => {
            console.log(response);

            setIsUploading(false);
            setError(null);
          })
          .catch(e => {
            console.error(e);

            setIsUploading(false);
            setError(null);
          });
      }
    },
    accept: {
      'text/csv': [],
      'application/csv': [],
      'text/x-csv': [],
      'application/x-csv': [],
      'text/comma-separated-values': [],
      'text/x-comma-separated-values': []
    },
    maxSize: 10485760
  });

  const showDialog = e => {
    e.preventDefault();

    open();
  }

  return (
    <MainContent>
      <div className="card card_br_24 card_24 card_shadow card_align_center card_flex">
        <SectionHead
          align="center"
          title={
            <>
              <div className="email-templates-subtitle-2-medium">Upload subscribers</div>
            </>
          }
        />
        <div
          className={`file-uploader mt-24${validationError ? ' file-uploader_invalid' : ''} text-center`}
          {...getRootProps()}
        >
          <UiCaptionRegular
            text="Drag and drop CSV here or"
            className="color-secondary-colour-secondary-7"
          />
          <UiButton
            size="small"
            design="secondary"
            text="Browse CSV"
            className="mt-24"
            disabled={disabled}
            onClick={showDialog}
          />
          <input {...getInputProps()} />
          {isUploading && <Preloader absolute={true} overflow={true} />}
        </div>
        {validationError ? (
          <UiCaptionRegular
            className="mt-4 color-dust-red-red-5"
            text={validationError.message}
          />
        ) : (
          <>{error ? <Error message={error} /> : ''}</>
        )}
      </div>
    </MainContent>
  );
};

export default UploadSubscribersPage;